export default {
  data() {
    return {
      images: [],
      itemsCategories: [],
      category: "",
      subcategoryNameEn: "",
      subcategoryNameEs: "",
      texts: "",
      iTotalImages: 0,
      screenWidth: 0,
      bLoading: false,
    };
  },
  beforeMount() {
    this.texts = FILE.subcategoryTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  upload() {
    this.$refs.clearUpload.clear();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.$store.commit("setDialogAddSubcategory", false);
    this.subcategoryNameEn = "";
    this.subcategoryNameEs = "";
    this.category = "";
  },
  methods: {
    addCategory() {
      this.bLoading = true;

      const params = {
        oSubcategories: {
          sImageKey: "",
          sCategoryId: this.category,
          aTranslations: [
            {
              sName: this.subcategoryNameEn,
              sLang: "en",
            },
            {
              sName: this.subcategoryNameEs,
              sLang: "sp",
            },
          ],
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      if (this.images.length > 0) {
        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/subcategories`,
          params,
          config
        )
          .then((response) => {
            this.sendImage(response);
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.bLoading = false;
        this.mixError(this.texts.upload.message);
      }
    },
    getCategories() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: 1,
        },
      })
        .then((response) => {
          this.itemsCategories = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendImage(resp) {
      var form = new FormData();
      form.append("image", this.images[0]);
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/subcategories/storage/${resp.data.results.sSubcategoryId}`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
    close() {
      this.$store.commit("setDialogAddSubcategory", false);
      this.subcategoryNameEn = "";
      this.subcategoryNameEs = "";
      this.category = "";
      this.$refs.clearUpload.clear();
    },
    emitClose() {
      this.$store.commit("setDialogAddSubcategory", false);
      this.subcategoryNameEn = "";
      this.subcategoryNameEs = "";
      this.category = "";
      this.$refs.clearUpload.clear();
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    setImages(e) {
      this.images = e;
      this.iTotalImages = this.images.length;
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogAddSubcategory() {
      return this.$store.state.dialogAddSubcategory;
    },
    validateForm() {
      return (
        this.subcategoryNameEn !== "" &&
        this.subcategoryNameEs !== "" &&
        // this.category !== "" &&
        this.subcategoryNameEn !== null &&
        this.subcategoryNameEs !== null
        // this.category !== null
      );
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.subcategoryTexts[this.selectLanguage];
      }
    },
    dialogAddSubcategory: function() {
      if (this.dialogAddSubcategory) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.texts = FILE.subcategoryTexts[this.selectLanguage];
        this.getCategories();
      }
    },
  },
};
